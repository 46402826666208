import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { LeftContainerComponent } from './leftContainer/leftContCmp';
import { RightContainerComponent } from './rightContainer/rightContCmp';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboardCmp';
import {HeaderComponent} from './header/headerCmp';
import {LoginComponent} from './login/loginCmp';
import { FooterComponent } from './footer/footerCmp';
import { TitleService } from './services/titleSvc';
import { NavigationHelper } from 'src/libs/utilities/services/navigationHelper';
import { UrlHelper } from 'src/libs/http/services/urlHelper';
import { AppConfig, AppSession } from 'src/libs/main/public_api';
import { ScriptLoader } from 'src/libs/shared/services/scriptLoader';
import { RewardsComponent } from './rewards/rewardsCmp';
import { MatDialogModule} from '@angular/material/dialog';
import { SpinnerComponent } from "./spinner/spinnerCmp";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MhcErrorHandler} from "@mhc/sdk/src/lib/core-services";

// @ts-ignore
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, children: [
    { path: 'my-health-dashboard', component: RightContainerComponent },
    { path: 'manage-my-health', component: RightContainerComponent},
    { path: 'activity-tracking', component: RightContainerComponent},
    { path: 'action-plan', component: RightContainerComponent},
    { path: 'my-rewards', loadChildren: () => import('@mhc/sdk').then(m => m.CoreModule)},
    { path: 'redeem-rewards', loadChildren: () => import('@mhc/sdk').then(m => m.CoreModule)},
    { path: 'challenges', loadChildren: () => import('@mhc/sdk').then(m => m.CoreModule)},
    { path: 'achievements', component: RightContainerComponent},
    { path: 'programs', component: RightContainerComponent},
    { path: '', component: RightContainerComponent }
  ] },
   { path: '', component: LoginComponent },
//    {path: '**', component: LoginComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    LeftContainerComponent,
    RightContainerComponent,
    DashboardComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    RewardsComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
      BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
      MatDialogModule,
    RouterModule.forRoot(routes, {})
  ],
  providers: [
    TitleService,
    NavigationHelper,
    UrlHelper,
    HttpClientModule,
    ScriptLoader,
    AppSession,
    AppConfig,
        {
            provide: APP_BASE_HREF,
            useValue: '/'
        },
        {
          provide: 'Window',
          useValue: window
      },
    {
      provide: ErrorHandler,
      useClass: MhcErrorHandler
    }
    ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
