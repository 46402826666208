import { IHttpHeader } from '../interfaces/iHttpHeader';
import { IUrlParam } from '../interfaces/iUrlParam';
import { HttpMethod } from './../enums/httpMethodEnum';
import { ResponseType } from './../enums/responseTypeEnum';
import { IHttpRequestInterceptor } from './iHttpRequestInterceptor';
import { IHttpResponseInterceptor } from './iHttpResponseInterceptor';

/**
 * Interface representing HTTP request object
 */
export interface IHttpRequest<T = unknown> {
  /**
   * url of the http request
   * dynamic parameters can be included that will be replaced using urlParams
   *  Ex: member/:uid/sso. where :uid can be a urlParam
   */
  url: string;

  /**
   * list of url parameters that can either be part of the url or query string.
   * use in conjunction with url property.
   */
  urlParams?: Array<IUrlParam>;

  /**
   * list of http headers
   */
  headers?: Array<IHttpHeader>;

  /**
   * http method
   * Ex: POST, GET, DELETE, PUT etc...
   */
  method: string | HttpMethod;

  /**
   * if this property is true, and pending http request will be cancelled when user tries to navigate away from current route.
   */
  cancellable?: boolean;

  /**
   * denotes if http request data is cacheable, so it will avoid making anohter http call.
   */
  cacheable?: boolean | string;

  /**
   * timeout for http request. if value given, http request will be cancelled on timeout if still pendig.
   */
  timeout?: number;

  /**
   * array of response interceptors for this http request
   */
  responseInterceptors?: Array<IHttpResponseInterceptor>;

  /**
   * array of request interceptors for this request
   */
  requestInterceptors?: Array<IHttpRequestInterceptor>;

  /**
   * JSON data to be sent as part of request
   */
  data?: T;

  /**
   * set if http request data contains multipart data, which needs to be handled differently from httpclient
   */
  isMultiPartRequest?: boolean;

  /**
   * denotes if http request is reusable.
   * when existing pending request is already available do not make another http request for same
   */
  reusable?: boolean;

  /**
   * allows trimming spaces from request data and url parameter values
   */
  trimSpaces?: boolean;

  /**
   * meta-flow header to be sent to api.
   */
  metaFlow?: string;

  /**
   * used for passing the domain brandcd to api layer. identified by current browser page url.
   */
  metaBrandCd?: string;

  withCredentials?: boolean;

  responseType?: ResponseType;

  jsonpCallback?: string;

  maxRequests?: number;

  clearCache?: boolean;

  cacheKey?: string;

  isGraphql?: boolean;

  finalUrl?: string;

  body?: unknown;

  metaLocale?: string;

  metaConsumer?: string;
}
