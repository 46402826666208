import { Subject } from 'rxjs';
import * as metadata from './iMetadata';
/**
 * DO NOT add any new properties to this interface.
 */
export interface IAppSession {
  metaData: Omit<metadata.IMetadata, 'featureFlags'>;
  wcsHash: string;
  templateHash: string;
  isDebugMode: boolean;
  pageTitle: string;
  pageName: string;
  getLogoutUrl(): string | undefined;
  getAllRCPPlans?(): metadata.IEligibility[];
  getPrimaryEligibility?(): metadata.IEligibility;
  isVivrEligible?: boolean;
  isIframeMode?: boolean;
  iframeData?: { [k: string]: string };
  metadataEvent?: Subject<{ name: string; from: string }>;
  isMobileRequest?: boolean;
  isNMCEligible?: boolean;
  webMDTermsAccepted?: boolean;
  hasJIAInContract?(e: unknown): boolean;
  authComplete?: boolean;
  dashboardLoadComplete?: boolean;
  isPartnerLogin?: boolean;
  isPFLoaded?: boolean;
  hasJIAInContract?(e: unknown): boolean;
  isJAA?(): boolean;
  getActiveEligibilities?(): metadata.IEligibility[];
  getFutureEligibilities?(): metadata.IEligibility[];
  userLocation?: {
    coords: {
      readonly accuracy: number;
      readonly altitude: number | null;
      readonly altitudeAccuracy: number | null;
      readonly heading: number | null;
      readonly latitude: number;
      readonly longitude: number;
      readonly speed: number | null;
    };
  };
  mobileOS?: string;
  claims?: {
    fromPharmacyPage?: boolean;
    providerFilter?: string;
  };
  isCarveOutCDH?(): boolean;
  healthwellness?: {
    subtype: string;
    fromAdaGinaPage: boolean;
    prefUid: string;
    defulted: string;
  };
  landingPage?: {};
  omeMap?: {};
  serverTime?: unknown;
  timeStamp?: unknown;
  engageEvent?: Subject<{ code: string; activityType?: unknown }>;
  showOnBoardingModal?: boolean;
  dataFromPf?: unknown;
  rxList?: unknown;
  isSecureMessageSuppressed?(): boolean;
  hasChatBot?(): boolean;
  hasMedical?(): boolean;
  sundaySkyScript?: boolean;
  hpoContractUid?: string;
  hasGAContent?(): boolean;
  isPreAuthenticateMode?: boolean;
  classicThemeLoaded?: boolean;
  locale?: string;
}
