export interface IWindow {
  readonly caches: CacheStorage;
  readonly clientInformation: Navigator;
  readonly closed: boolean;
  readonly crypto: Crypto;
  defaultStatus: string;
  readonly devicePixelRatio: number;
  readonly doNotTrack: string;
  readonly document: Document;
  event: Event | undefined;
  readonly external: External;
  readonly frameElement: Element;
  readonly frames: IWindow | unknown;
  readonly history: History;
  readonly innerHeight: number;
  readonly innerWidth: number;
  readonly isSecureContext: boolean;
  readonly length: number;
  location: Location;
  readonly locationbar: BarProp;
  readonly menubar: BarProp;
  readonly msCredentials: unknown;
  name: string;
  readonly navigator: Navigator;
  offscreenBuffering: string | boolean;
  isIE?: boolean;
  isEdge?: boolean;
  isFirefox?: boolean;
  _genesys?: unknown;
  isIos?: boolean;
  isAndroid?: boolean;
  onabort: (this: IWindow | unknown, ev: UIEvent) => unknown;
  onafterprint: (this: IWindow | unknown, ev: Event) => unknown;
  onbeforeprint: (this: IWindow | unknown, ev: Event) => unknown;
  onbeforeunload: (this: IWindow | unknown, ev: BeforeUnloadEvent) => unknown;
  onblur: (this: IWindow | unknown, ev: FocusEvent) => unknown;
  oncanplay: (this: IWindow | unknown, ev: Event) => unknown;
  oncanplaythrough: (this: IWindow | unknown, ev: Event) => unknown;
  onchange: (this: IWindow | unknown, ev: Event) => unknown;
  onclick: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  oncompassneedscalibration: (this: IWindow | unknown, ev: Event) => unknown;
  oncontextmenu: (this: IWindow | unknown, ev: PointerEvent) => unknown;
  ondblclick: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  ondevicemotion: (this: IWindow | unknown, ev: DeviceMotionEvent) => unknown;
  ondeviceorientation: (this: IWindow | unknown, ev: DeviceOrientationEvent) => unknown;
  ondrag: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondragend: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondragenter: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondragleave: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondragover: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondragstart: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondrop: (this: IWindow | unknown, ev: DragEvent) => unknown;
  ondurationchange: (this: IWindow | unknown, ev: Event) => unknown;
  onemptied: (this: IWindow | unknown, ev: Event) => unknown;
  onerror: unknown;
  onfocus: (this: IWindow | unknown, ev: FocusEvent) => unknown;
  onhashchange: (this: IWindow | unknown, ev: HashChangeEvent) => unknown;
  oninput: (this: IWindow | unknown, ev: Event) => unknown;
  oninvalid: (this: IWindow | unknown, ev: Event) => unknown;
  onkeydown: (this: IWindow | unknown, ev: KeyboardEvent) => unknown;
  onkeypress: (this: IWindow | unknown, ev: KeyboardEvent) => unknown;
  onkeyup: (this: IWindow | unknown, ev: KeyboardEvent) => unknown;
  onload: (this: IWindow | unknown, ev: Event) => unknown;
  onloadeddata: (this: IWindow | unknown, ev: Event) => unknown;
  onloadedmetadata: (this: IWindow | unknown, ev: Event) => unknown;
  onloadstart: (this: IWindow | unknown, ev: Event) => unknown;
  onmessage: (this: IWindow | unknown, ev: MessageEvent) => unknown;
  onmousedown: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmouseenter: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmouseleave: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmousemove: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmouseout: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmouseover: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmouseup: (this: IWindow | unknown, ev: MouseEvent) => unknown;
  onmousewheel: (this: IWindow | unknown, ev: WheelEvent) => unknown;
  onoffline: (this: IWindow | unknown, ev: Event) => unknown;
  ononline: (this: IWindow | unknown, ev: Event) => unknown;
  onorientationchange: (this: IWindow | unknown, ev: Event) => unknown;
  onpagehide: (this: IWindow | unknown, ev: PageTransitionEvent) => unknown;
  onpageshow: (this: IWindow | unknown, ev: PageTransitionEvent) => unknown;
  onpause: (this: IWindow | unknown, ev: Event) => unknown;
  onplay: (this: IWindow | unknown, ev: Event) => unknown;
  onplaying: (this: IWindow | unknown, ev: Event) => unknown;
  onpopstate: (this: IWindow | unknown, ev: PopStateEvent) => unknown;
  onprogress: (this: IWindow | unknown, ev: ProgressEvent) => unknown;
  onratechange: (this: IWindow | unknown, ev: Event) => unknown;
  onreadystatechange: (this: IWindow | unknown, ev: ProgressEvent) => unknown;
  onreset: (this: IWindow | unknown, ev: Event) => unknown;
  onresize: (this: IWindow | unknown, ev: UIEvent) => unknown;
  onscroll: (this: IWindow | unknown, ev: UIEvent) => unknown;
  onseeked: (this: IWindow | unknown, ev: Event) => unknown;
  onseeking: (this: IWindow | unknown, ev: Event) => unknown;
  onselect: (this: IWindow | unknown, ev: UIEvent) => unknown;
  onstalled: (this: IWindow | unknown, ev: Event) => unknown;
  onstorage: (this: IWindow | unknown, ev: StorageEvent) => unknown;
  onsubmit: (this: IWindow | unknown, ev: Event) => unknown;
  onsuspend: (this: IWindow | unknown, ev: Event) => unknown;
  ontimeupdate: (this: IWindow | unknown, ev: Event) => unknown;
  ontouchcancel: (ev: TouchEvent) => unknown;
  ontouchend: (ev: TouchEvent) => unknown;
  ontouchmove: (ev: TouchEvent) => unknown;
  ontouchstart: (ev: TouchEvent) => unknown;
  onunload: (this: IWindow | unknown, ev: Event) => unknown;
  onvolumechange: (this: IWindow | unknown, ev: Event) => unknown;
  onwaiting: (this: IWindow | unknown, ev: Event) => unknown;
  opener: unknown;
  orientation: string | number;
  readonly outerHeight: number;
  readonly outerWidth: number;
  readonly pageXOffset: number;
  readonly pageYOffset: number;
  readonly parent: IWindow | unknown;
  readonly performance: Performance;
  readonly personalbar: BarProp;
  readonly screen: Screen;
  readonly screenLeft: number;
  readonly screenTop: number;
  readonly screenX: number;
  readonly screenY: number;
  readonly scrollX: number;
  readonly scrollY: number;
  readonly scrollbars: BarProp;
  readonly self: IWindow | unknown;
  readonly speechSynthesis: SpeechSynthesis;
  status: string;
  readonly statusbar: BarProp;
  readonly styleMedia: StyleMedia;
  readonly toolbar: BarProp;
  readonly top: IWindow | unknown;
  readonly window: IWindow | unknown;
  URL: typeof URL;
  URLSearchParams: typeof URLSearchParams;
  Blob: typeof Blob;
  customElements: CustomElementRegistry;
  alert(message?: unknown): void;
  blur(): void;
  cancelAnimationFrame(handle: number): void;
  captureEvents(): void;
  close(): void;
  confirm(message?: string): boolean;
  focus(): void;
  getComputedStyle(elt: Element, pseudoElt?: string): CSSStyleDeclaration;
  getMatchedCSSRules(elt: Element, pseudoElt?: string): CSSRuleList;
  getSelection(): Selection;
  matchMedia(mediaQuery: string): MediaQueryList;
  moveBy(x?: number, y?: number): void;
  moveTo(x?: number, y?: number): void;
  msWriteProfilerMark(profilerMarkName: string): void;
  open(url?: string, target?: string, features?: string, replace?: boolean): IWindow;
  postMessage(message: unknown, targetOrigin: string, transfer?: unknown[]): void;
  print(): void;
  prompt(message?: string, _default?: string): string | null;
  releaseEvents(): void;
  requestAnimationFrame(callback: FrameRequestCallback): number;
  resizeBy(x?: number, y?: number): void;
  resizeTo(x?: number, y?: number): void;
  scroll(x?: number, y?: number): void;
  scrollBy(x?: number, y?: number): void;
  scrollTo(x?: number, y?: number): void;
  stop(): void;
  webkitCancelAnimationFrame(handle: number): void;
  webkitRequestAnimationFrame(callback: FrameRequestCallback): number;
  createImageBitmap(image: HTMLImageElement | SVGImageElement | HTMLVideoElement | HTMLCanvasElement | ImageBitmap | ImageData | Blob, options?: ImageBitmapOptions): Promise<ImageBitmap>;
  createImageBitmap(
    image: HTMLImageElement | SVGImageElement | HTMLVideoElement | HTMLCanvasElement | ImageBitmap | ImageData | Blob,
    sx: number,
    sy: number,
    sw: number,
    sh: number,
    options?: ImageBitmapOptions
  ): Promise<ImageBitmap>;
  scroll(options?: ScrollToOptions): void;
  scrollTo(options?: ScrollToOptions): void;
  scrollBy(options?: ScrollToOptions): void;
  addEventListener<K extends keyof WindowEventMap>(type: K, listener: (this: IWindow | unknown, ev: WindowEventMap[K]) => unknown, useCapture?: boolean): void;
  addEventListener(type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean): void;
  scriptHash?: string;
  sessionStorage: IStorage;
  localStorage: IStorage;
  digitalData: unknown;
  setIDCardData: (i: unknown) => void;
  setClaimData: (i: unknown) => void;
  unescape: (e: string) => string;
  close: () => void;
  setPcpRemote: (d: unknown) => void;
  planMatchWindow: unknown;
}

export interface IStorage {
  getItem: (key: string) => unknown;
  setItem: (key: string, item: unknown) => void;
  removeItem: (key: string) => void;
  forms: unknown;
}