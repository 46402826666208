import { IHttpHeader } from './iHttpHeader';

export interface IHttpResponse<T = unknown> {
  status: number;
  data: T;
  headers?: Array<IHttpHeader>;
  hasNewData?: boolean;
  redirectUrl?: string;
  urlWithParams?: string;
  fromBrowserCache?: boolean;
}

export interface IRawHttpResponse<T = unknown> {
  body: T;
  headers: Headers;
  status: number;
  statusText: string;
  url: string;
}
