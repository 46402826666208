import { Component, ComponentFactory, Type } from '@angular/core';

export interface ISelectorComponent extends Component {
  component: Type<unknown>;
  selector: string;
  inputs?: Array<string>;
  priority?: number;
  factory?: ComponentFactory<unknown>;
  replaceNode?: boolean;
}
